// Imports


// Plugins
const plugins = [{
  location: "/home/mrh/src/safe-react/rs-blog-demo",
  plugins: [],
  hooks: {}
}]

// Export em!
export default plugins